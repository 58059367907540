<template lang="pug">
  img.app-picture__img(
    ref='image'
    :srcset='srcset'
    :sizes='sizes'
    :media='media'
    :src='src'
    :alt='alt'
    :width='width'
    :height='height'
    :loading="loading"
  )
</template>

<script lang="ts">
  import type { PropType } from 'vue';
  import { getImagesBySizes, getSizesPicture, getSrcSetByDPR } from '@devhacker/shared/utils/image';
  import type { Img } from '@devhacker/shared/utils/image/types';

  export default defineNuxtComponent({
    name: 'AppPicture',

    props: {
      alt: {
        type: String as PropType<string>,
        default: 'Изображение',
      },

      images: {
        type: Array as PropType<Img[]>,
        default: () => [],
      },

      defaultImages: {
        type: Array as PropType<Img[]>,
        default: () => [],
      },

      sizesSliced: {
        type: Array as PropType<number[]>,
        default: () => [],
      },

      sizesByDevice: {
        type: Object as PropType<IImageSizesByDevice>,
        default() {
          return {
            mobile: 'calc(100vw - 16px * 2)',
            tablet: 'calc(100vw - 24px * 2 * 2)',
            desktop: '900px',
          };
        },
      },

      loading: {
        type: String,
        default: 'lazy',
      },
    },

    computed: {
      // опционально, пока не используется
      media(): string | null {
        return null;
      },

      imagesCurrent(): Img[] {
        return this.images && this.images.length ? this.images : this.defaultImages;
      },

      imagesBySizes(): Img[] {
        return getImagesBySizes(this.sizesSliced, this.imagesCurrent);
      },

      imagesBySizesFirst(): Img | undefined {
        const [firstImage] = this.imagesBySizes;

        return firstImage;
      },

      src(): string {
        return this.imagesBySizesFirst?.url ?? '';
      },

      width(): number {
        return this.imagesBySizesFirst?.width || 0;
      },

      height(): number {
        return this.imagesBySizesFirst?.height || 0;
      },

      srcset(): string {
        return getSrcSetByDPR(this.sizesSliced, this.imagesCurrent);
      },

      sizes(): string {
        return getSizesPicture(this.sizesByDevice);
      },
    },
  });
</script>

<style lang="scss" scoped>
  .app-picture {
    &__img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
</style>
